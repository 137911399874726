import { useEffect, useRef, useState } from "react";
// material-ui
import {
  Box,
  Menu,
  MenuItem,
  Button,
  Skeleton,
  Badge,
  TablePagination,
  Modal,
  IconButton,
  Tooltip,
  Grid,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
// project import
import emptyImage from "../../assets/images/icons8-empty-64.png";
import { BASE_URL, get, post } from "Urls/api";
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { formatDate } from "utils/formatDate";
import AnimateButton from "components/@extended/AnimateButton";
import toastAlert from "components/ToastAlert/index";

export default function OrderTable() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: window.innerWidth >= 1024 ? 600 : "auto",
    bgcolor: "background.paper",
    border: "1px solid lightGrey",
    boxShadow: 24,
    p: 4,
  };
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // get all products

  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedIndex, setSelectedIndex] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [loader, setLoader] = useState(true);
  const [loaderD, setLoaderD] = useState(false);

  const [PaymentId, setPaymentId] = useState("");

  // Use effect
  const fetchAllproducts = async () => {
    try {
      const apiData = await get("user/paymentRequestGet"); // Specify the endpoint you want to call
      console.log("ALL Request");
      console.log(apiData);

      if (apiData.error) {
        setLoader(false);
      } else {
        setProducts(apiData.result);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchAllproducts();
  }, [page]);
  return (
    <Box>
      {loader ? (
        <>
          <Grid container spacing={2} pt={10}>
            {/* For variant="text", adjust the height via font-size */}

            <Grid item xs={12} md={12}>
              <Skeleton variant="rectangular" height={100} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rectangular" height={100} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rectangular" height={100} />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} align="right">
              <TableContainer
                sx={{
                  width: "100%",
                  overflowX: "auto",
                  position: "relative",
                  display: "block",
                  maxWidth: "100%",
                  "& td, & th": { whiteSpace: "nowrap" },
                }}
              >
                <Table
                  aria-labelledby="tableTitle"
                  sx={{
                    "& .MuiTableCell-root:first-of-type": {
                      pl: 2,
                    },
                    "& .MuiTableCell-root:last-of-type": {
                      pr: 3,
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Status</TableCell>

                      {/* <TableCell align="center">Description</TableCell> */}
                      <TableCell align="center">Created</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.length === 0 ? (
                      <>
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Avatar
                                variant="square"
                                src={emptyImage}
                                alt="No Data"
                                style={{
                                  width: "64px",
                                  height: "auto",
                                  marginBlock: "2%",
                                }}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <>
                        {products
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <>
                              <TableRow
                                key={row?.payment_request_id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{ cursor: "pointer" }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                      <Avatar
                                        variant="square"
                                        src={`${BASE_URL}${row?.screenshot}`}
                                        style={{
                                          backgroundColor: "#03356b",
                                          textTransform: "capitalize",
                                        }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                      <Typography
                                        variant="h5"
                                        style={{ fontWeight: 200 }}
                                      >
                                        {row?.full_name}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </TableCell>

                                <TableCell align="center">
                                  {row?.email}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.status === "Pending" ? (
                                    <Badge
                                      badgeContent="PENDING"
                                      color="warning"
                                    ></Badge>
                                  ) : (
                                    <Badge
                                      badgeContent="COMPLETED"
                                      color="success"
                                    ></Badge>
                                  )}
                                </TableCell>

                                <TableCell align="center">
                                  {formatDate(row?.created_at)}
                                </TableCell>

                                <TableCell align="center">
                                  <Tooltip title="Action">
                                    <EllipsisOutlined
                                      ref={anchorRef}
                                      style={{
                                        fontSize: "30px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(event) => {
                                        setSelectedIndex(row.screenshot);
                                        setPaymentId(row.payment_request_id);

                                        setAnchorEl(event.currentTarget);
                                      }}
                                    />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={12} align="right">
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={products.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                boxShadow: "0px 2px 30px -15px rgba(94,94,107,0.67)",
                mt: 1.5,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              onClick={() => {
                setIsModalOpen(true);
                setAnchorEl(null)
              }}
            >
              View ScreenShot
            </MenuItem>
            <MenuItem
              onClick={async () => {
                try {
                  const postData = {
                    payment_request_id: PaymentId,
                  };
                  const apiData = await post(
                    "user/updatePaymentReqStatus",
                    postData
                  ); // Specify the endpoint you want to call
                  console.log("ALL Request");
                  console.log(apiData);

                  if (apiData.error) {
                    toastAlert("error", apiData.message);
                    setLoaderD(false);
                  } else {
                    setLoaderD(false);
                    toastAlert("success", apiData.message);
                    fetchAllproducts();
                    setAnchorEl(null)
                  }
                } catch (error) {
                  setLoaderD(false);
                }
              }}
            >
              Complete Request
            </MenuItem>
          </Menu>
        </>
      )}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={`${BASE_URL}${selectedIndex}`}
              alt="Screenshot"
              width="500px"
              height="auto"
            />
          </div>
        </Box>
      </Modal>
    </Box>
  );
}
