import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack, Avatar,Typography } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'components/Logo';
import logo_code from '../../../../assets/images/logom.png'

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();
  const navigate=useNavigate();
  const [user_name,setuser_name]=useState('')
  const [user,setuser]=useState('')


  useEffect(() => {
    const userSession = JSON.parse(localStorage.getItem("@AdminSession"));
    if (userSession === null || userSession === undefined) {
      navigate('/login')
    } else {
      setuser_name(userSession.user.user_name)
      setuser(userSession.user)
      // navigate('/')
    }

  }, []);

  return (
    // only available in paid version
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack  spacing={1}
      style={{
        
      }}
      alignItems="center">
        {/* <Logo /> */}
        <Avatar src={logo_code} variant="square"
        style={{
          width: "150px",
          marginTop: "20px",
        height: "auto",
        objectFit: "contain",
        }}
        //  style={{width:'200px',objectFit:'contain'}}
         alt="Logo"/>
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
