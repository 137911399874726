// material-ui
import {
  Typography, Grid
} from '@mui/material';
// project import
import OrdersTable from './OrdersTable';

const Payment = () => {

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h3" style={{ fontWeight: 700 }}>Payment Requests</Typography>

        </Grid>

        <Grid item xs={12} md={12}>
          <OrdersTable />
        </Grid>
      
      </Grid>

    </>
  )
};

export default Payment;
