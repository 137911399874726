import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';

import { Box, Select, FormControl, Skeleton, Button, InputLabel, OutlinedInput, MenuItem, Menu, TablePagination, InputAdornment, Pagination, TextField, Divider, ListItemText, Popper, ListItemIcon, ListItemButton, List, Tooltip, Grid, Avatar, Stack, CardContent, ClickAwayListener, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// project import
import Dot from 'components/@extended/Dot';
import { BASE_URL, get, post } from 'Urls/api';
import {
  EllipsisOutlined,
  SearchOutlined,
  ExportOutlined
} from '@ant-design/icons';
import emptyImage from "../../assets/images/icons8-empty-64.png"
import toastAlert from 'components/ToastAlert/index';
import { formatDate } from 'utils/formatDate';
import AnimateButton from 'components/@extended/AnimateButton';
import ClipLoader from '../../../node_modules/react-spinners/ClipLoader';
import ExportCSV from 'utils/ExportCSV';

// ==============================|| ORDER TABLE - STATUS ||============================== //

const OrderStatus = ({ status }) => {
  let color;
  let title;

  switch (status) {
    case 'active':
      color = 'success';
      title = 'Active';
      break;
    case 'inactive':
      color = 'error';
      title = 'In Active';
      break;
    default:
      color = 'primary';
      title = 'None';
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{title}</Typography>
    </Stack>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.number
};

// ==============================|| ORDER TABLE ||============================== //

export default function OrderTable() {
  const [products, setProducts] = useState([]);
  const [Allproducts, setAllProducts] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [opendel, setOpendel] = useState(false);

  const handleClosedel = () => {
    setOpendel(false);
  };
  // modal cancel reason 
  const [opendelSubs, setOpendelSubs] = useState(false);

  const handleClosedelSubs = () => {
    setOpendelSubs(false);
  };
  const [selectedIndex, setSelectedIndex] = useState(0);
  // Delete Product 
  const [loading, setLoading] = useState(false)
  const DeleteProduct = async () => {
    setLoading(true)
    console.log(selectedIndex)
    if (cancellationReason === "" || cancellationReason === null) {
      toastAlert("error", "Please Enter Reason")
    } else {
      const postData = {
        subscription_id: selectedIndex,
        reason: cancellationReason
      }
      const apiData = await post('user/cancelSubscriptionReq', postData); // Specify the endpoint you want to call
      console.log("Reason Sent ")

      console.log(apiData)

      if (apiData.error) {
        toastAlert("error", apiData.message)
        setLoading(false)

      } else {
        toastAlert("success", apiData.message)
        setOpendelSubs(false);
        setCancellationReason('')
        setLoading(false)

      }
    }


  }
  // Pagination 
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [loader, setLoader] = useState(true)
  const [cancellationReason, setCancellationReason] = useState('')

  // Use effect 
  const [loadingData, setLoadingData] = useState(false)
  const [loaderExport, setLoaderExport] = useState(false)
  const fetchAllproducts = async () => {
    try {

      const apiData = await get('blogs/getAllBlogs'); // Specify the endpoint you want to call
      console.log("ALL blogs")
      console.log(apiData)
      if (apiData.error) {
        setLoader(false)
      } else {
        setProducts(apiData.result)
        setAllProducts(apiData.result)
        setLoadingData(false)
        setLoader(false)

      }


    } catch (error) {
      setLoader(false)
    }
  }
  // reject request 
  const [statusData, setStatusData] = useState('')
  const RejectRequest = async () => {
    setLoading(true)
    const postData = {
      blog_id: selectedIndex,
      status: statusData
      // rowsPerPage: rowsPerPage,
      // page: page
    }
    const apiData = await post('blogs/updateBlog', postData); // Specify the endpoint you want to call
    console.log("ALL Blogs")

    console.log(apiData)

    if (apiData.error) {
      toastAlert("error", apiData.message)
      setLoading(false)

    } else {
      toastAlert("success", apiData.message)
      fetchAllproducts()
      window.location.reload()
      setOpendel(false);
      setLoading(false)


    }
  }
  // Filter 
  const [filterByStatus, setfilterByStatus] = React.useState('');
  const [filteredData, setFilteredData] = React.useState([])

  const handleChange = (event) => {
    setfilterByStatus(event.target.value);
    const filtered = Allproducts.filter(item => item.status === event.target.value);
    console.log(filtered)
    if (filtered.length === 0) {
      toastAlert("error", "No data Exist for this Status")
    } else {
      setFilteredData(filtered);
    }

  };
  // Pagination 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Helper function to strip HTML tags
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  // Function to truncate text and show tooltip on hover
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return `${text.slice(0, maxLength)}...`;
  };
  useEffect(() => {
    fetchAllproducts()
  }, [page, rowsPerPage]);
  return (
    <Box>
      {loader ? <>
        <Grid container spacing={2} pt={10} >
          {/* For variant="text", adjust the height via font-size */}

          <Grid item xs={12} md={12} >

            <Skeleton variant="rectangular" height={100} />

          </Grid>
          <Grid item xs={12} md={12} >

            <Skeleton variant="rectangular" height={100} />

          </Grid>
          <Grid item xs={12} md={12} >

            <Skeleton variant="rectangular" height={100} />

          </Grid>

        </Grid>
      </> : <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} align="right" style={{ display: 'flex', justifyContent: 'right' }}>
            {/* Search bar to search from table by name 
           */}
            <Box sx={{ minWidth: 190 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Filter by Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filterByStatus}
                  label="Filter by Status"
                  onChange={handleChange}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">In Active</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <TextField
              id="outlined-basic"
              label="Search by title"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setLoadingData(true)
                if (e.target.value === "" || e.target.value === null) {


                  setProducts(Allproducts)
                  setLoadingData(false)

                } else {
                  const filterData = Allproducts.filter((item) => {
                    return item.title && item.title.toLowerCase().includes(e.target.value.toLowerCase())
                  }
                  )
                  console.log(filterData)
                  setProducts(filterData)
                  setLoadingData(false)

                }
              }}
              style={{ width: '300px', marginLeft: '20px' }}
              InputProps={{

                endAdornment: (
                  <InputAdornment position="end">
                    {loadingData ? <>
                      <ClipLoader color="#03356b" loading={loadingData} size={25} />
                    </> : <SearchOutlined />
                    }
                  </InputAdornment>
                )
              }}
            />
            <AnimateButton>
              <Button disableElevation
                onClick={
                  () => {
                    // export as csv 
                    setLoaderExport(true)
                    setLoaderExport(false)


                  }
                }
                variant="outlined"
                startIcon={loaderExport ?
                  <ClipLoader color="#03356b" loading={loaderExport} size={25} /> :
                  <ExportOutlined />} size="medium"
                style={{
                  marginLeft: '20px',
                  color: 'rgb(76 91 104)',
                  fontWeight: 700,
                  backgroundColor: 'white', border: '1px solid lightGray', boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)'
                }} color="secondary">
                <ExportCSV data={
                  products.map((item) => {
                    return {
                      " Name": item?.title,
                      "Description": item?.description,
                      "Status": item?.status,
                      "Date": formatDate(item?.created_at),
                    }
                  })
                } FileName="News Section" />

              </Button>
            </AnimateButton>
          </Grid>
          <Grid item xs={12} md={12} align="right">
            <TableContainer
              sx={{
                width: '100%',
                overflowX: 'auto',
                position: 'relative',
                display: 'block',
                maxWidth: '100%',
                '& td, & th': { whiteSpace: 'nowrap' }
              }}
            >
              <Table
                aria-labelledby="tableTitle"
                sx={{
                  '& .MuiTableCell-root:first-of-type': {
                    pl: 2
                  },
                  '& .MuiTableCell-root:last-of-type': {
                    pr: 3
                  }
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Title</TableCell>
                    <TableCell align="center">Description </TableCell>
                    <TableCell align="center"> Status </TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.length === 0 ? <>
                    {products.length === 0 ? <>
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Box style={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}>
                            <Avatar variant="square" src={emptyImage} alt="No Data" style={{ width: '64px', height: 'auto', marginBlock: '2%' }} />
                          </Box>
                        </TableCell>

                      </TableRow>
                    </> :

                      <>
                        {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                          <TableRow
                            key={row.product_id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row" style={{ cursor: 'pointer' }}
                            // onClick={() => navigate(`/subscribed_product_detail/${row?.subscription?.subscription_id}`)}>
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={2}>
                                  <Avatar variant="square"
                                    src={`${BASE_URL}${row?.image}`}
                                    style={{
                                      backgroundColor: '#03356b',
                                      textTransform: 'capitalize'
                                    }} />

                                </Grid>
                                <Grid item xs={12} md={10}>
                                  <Typography variant="h5" style={{ fontWeight: 200 }}>{row?.title}</Typography>
                                  {/* <Typography variant="h6" style={{ fontWeight: 200, color: 'gray' }}>{row?.email}</Typography> */}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell align="center"><Tooltip title={row?.description} arrow>
                              <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '150px' }}>
                                {truncateText(stripHtmlTags(row?.description), 50)} {/* Adjust the maxLength value as needed */}
                              </div>
                            </Tooltip></TableCell>
                            <TableCell align="center">  <OrderStatus status={row.status} /></TableCell>


                            <TableCell align="center">{formatDate(row?.created_at)}</TableCell>
                            {/* get subscription ending date by today date + package duration */}



                            <TableCell align="center">
                              <Tooltip title="Action">
                                <EllipsisOutlined
                                  //  ref={anchorRef}
                                  style={{ fontSize: '30px', cursor: 'pointer' }} onClick={
                                    (e) => {
                                      setSelectedIndex(row?.blog_id)
                                      handleClick(e)
                                    }} />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>}

                  </> :
                    <>
                      {filteredData.map((row) => (
                        <TableRow
                          key={row.product_id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row" style={{ cursor: 'pointer' }}
                          // onClick={() => navigate(`/subscribed_product_detail/${row?.subscription?.subscription_id}`)}>
                          >
                            <Grid container spacing={2}>

                              <Grid item xs={10} md={10}>
                                <Typography variant="h5" style={{ fontWeight: 200 }}>{row?.title}</Typography>
                                {/* <Typography variant="h6" style={{ fontWeight: 200, color: 'gray' }}>{row?.email}</Typography> */}
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell align="center"><Tooltip title={row?.description} arrow>
                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '150px' }}>
                              {truncateText(stripHtmlTags(row?.description), 50)} {/* Adjust the maxLength value as needed */}
                            </div>
                          </Tooltip></TableCell>
                          <TableCell align="center">  <OrderStatus status={row.status} /></TableCell>


                          <TableCell align="center">{formatDate(row?.created_at)}</TableCell>
                          {/* get subscription ending date by today date + package duration */}



                          <TableCell align="center">
                            <Tooltip title="Action">
                              <EllipsisOutlined
                                //  ref={anchorRef}
                                style={{ fontSize: '30px', cursor: 'pointer' }} onClick={
                                  (e) => {
                                    setSelectedIndex(row?.blog_id)
                                    handleClick(e)
                                  }} />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>}

                </TableBody>
              </Table>
            </TableContainer>


          </Grid>
          <Grid item xs={12} md={12} align="right">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={products.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </Grid>
        </Grid>

        {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={productsLength}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              boxShadow: '0px 2px 30px -15px rgba(94,94,107,0.67)',
              mt: 1.5,

            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >

          <MenuItem style={{ color: 'green' }} onClick={() => {
            setAnchorEl(null)
            setOpendel(true)
            setStatusData('active')

            //  navigate(`/subscribed_product_detail/${selectedIndex}`)

          }
          }>Active </MenuItem>
          <MenuItem style={{ color: 'red' }} onClick={() => {
            setAnchorEl(null)
            setOpendel(true)
            setStatusData('inactive')
          }}>In Active </MenuItem>
        </Menu>
        {/* <Popper 
        key={selectedIndex}
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 9]
                }
              }
            ]
          }}
        >
          {({ TransitionProps }) => (
            <Transitions type="fade" in={open} {...TransitionProps}>
              {open && (
                <Paper
                  sx={{
                    boxShadow: theme.customShadows.z1,
                    width: 190,
                    minWidth: 140,
                    maxWidth: 190,
                    [theme.breakpoints.down('md')]: {
                      maxWidth: 250
                    }
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard elevation={0} border={false} content={false}>
                      {open && (
                        <>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>

                              <ListItemButton selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 1)}>
                                <ListItemIcon>
                                  <EditOutlined />
                                </ListItemIcon>
                                <ListItemText primary="Edit Product" />
                              </ListItemButton>
                              <ListItemButton selected={selectedIndex === 4} onClick={() => handleDelete()}>
                                <ListItemIcon>
                                  <DeleteOutlined style={{ color: 'red' }} />
                                </ListItemIcon>
                                <ListItemText primary="Delete" style={{ color: 'red' }} />
                              </ListItemButton>
                            </List>
                          </Box>

                        </>
                      )}
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              )}
            </Transitions>
          )}
        </Popper> */}
        {/* delete Dialog  */}
        <Dialog
          open={opendel}
          onClose={handleClosedel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 2, fontSize: '20px', fontWeight: 700 }} id="customized-dialog-title">
            Confirmation Dialog
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <DialogContentText id="alert-dialog-description" style={{ fontSize: '15px', color: 'black' }}>
                  Are you sure you want to {setStatusData === "active" ? <>In Active</> : <>Active</>} this News?
                </DialogContentText>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} align="right">
                <Button disableElevation onClick={handleClosedel} variant="outlined" size="medium" style={{ marginRight: '12px', fontWeight: 700, color: 'gray', boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)' }} color="secondary">
                  No
                </Button>
                <Button
                  disabled={loading}
                  startIcon={loading ? <ClipLoader color="#fff" loading={loading} size={25} /> : null}

                  onClick={() => RejectRequest()} disableElevation variant="contained" size="medium" style={{ fontWeight: 700, color: 'white', boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)' }} color="error">
                  Yes
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog
          open={opendelSubs}
          onClose={handleClosedelSubs}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 2, fontSize: '20px', fontWeight: 700 }} id="customized-dialog-title">
            We are sorry to see you go 😔
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* <Grid item xs={12}>
                <DialogContentText id="alert-dialog-description" style={{ fontSize: '15px', color: 'black' }}>
                  Are you sure you want to delete?
                </DialogContentText>
              </Grid> */}
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Typography variant="h5">Reason for cancellation</Typography>

                  <Stack spacing={1} display="flex" direction="row">
                    <OutlinedInput
                      id="cancellation_reason"
                      type="text"
                      value={cancellationReason}
                      name="cancellation_reason"
                      // onBlur={handleBlur}
                      multiline
                      rows={4}
                      onChange={(e) => setCancellationReason(e.target.value)}
                      fullWidth

                    // error={Boolean(touched.product_id_stripe && errors.product_id_stripe)}
                    />

                    {/* <Button disableElevation variant="outlined" onClick={() => window.open('https://dashboard.stripe.com/', '_blank')} startIcon={<PaperClipOutlined />} size="medium" style={{ color: 'rgb(76 91 104)', fontWeight: 700, backgroundColor: 'white', border: '1px solid lightGray', boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)' }} color="secondary">
                            Stripe
                          </Button> */}
                  </Stack>

                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} align="right">
                <Button disableElevation onClick={handleClosedelSubs} variant="outlined" size="medium" style={{ marginRight: '12px', fontWeight: 700, color: 'gray', boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)' }} color="secondary">
                  Cancel
                </Button>
                <Button disabled={loading} onClick={() => DeleteProduct()} disableElevation variant="contained" size="medium" style={{ fontWeight: 700, color: 'white', boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)' }} color="primary">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>}
    </Box>
  );
}
