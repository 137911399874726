// material-ui
import {
  Typography, Grid, Button
} from '@mui/material';
import { useEffect, useState } from 'react';
// project import
import OrdersTable from './OrdersTable';
import AnimateButton from 'components/@extended/AnimateButton';
import {
  PlusOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const FAQ = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h3" style={{ fontWeight: 700 }}>FAQs</Typography>

        </Grid>
        <Grid item xs={12} md={3} style={{ display: 'flex', justifyContent: 'right' }}>

          <AnimateButton>
            <Button
              onClick={() => navigate('/faq_add')}
              disableElevation startIcon={<PlusOutlined />} size="medium" color="primary" variant="contained">
              FAQs
            </Button>
          </AnimateButton>
        </Grid>
       
        <Grid item xs={12} md={12}>
          <OrdersTable />
        </Grid>
      
      </Grid>

    </>
  )
};

export default FAQ;
