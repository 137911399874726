// material-ui
import {
  Typography, Grid, Button, Box,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Avatar,
  Stack,
  Tooltip,

} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import AnimateButton from 'components/@extended/AnimateButton';
import {
  PaperClipOutlined,
  UploadOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { ClipLoader } from "react-spinners";
import toastAlert from 'components/ToastAlert/index';
import { useNavigate } from 'react-router-dom';
import { get, post, postFormData } from 'Urls/api';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Editor } from '@tinymce/tinymce-react';
import apiKey from 'Urls/editorApiKey';

const NewsAdd = () => {
  //  Select Image 
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [ImageFile, setImageFile] = useState(null);


  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file)
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const navigate = useNavigate();
  const editorRef = useRef(null);
 const [about_me,setAbout_me]=useState('')
  return (
    <>
      <Grid container spacing={2}>


        <Grid item xs={12} md={12} style={{ position: 'relative' }}>

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              marginBottom: '2%'
            }}>
              <Tooltip title="Back">


                <Button
                  onClick={() => {
                    navigate(`/latest_news`)
                  }
                  }
                  className='poppinsRegularBold'
                  sx={{
                    // width: '100px',
                    paddingInline: '10px',
                    display: "block",
                    color: "#03356b",
                    backgroundColor: "none",
                    fontSize: '16px',
                    fontWeight: 600,
                    borderRadius: '50px',
                    textTransform: 'capitalize',
                    transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                    transitionDelay: '0.3s', // Add transition delay for hover
                    marginRight: '10px',
                    // border: '1px solid #03356b',
                    '&:hover': {
                      backgroundColor: "#03356b",
                      color: 'white', // Change text color to blue on hover
                    },
                  }} >
                  <ArrowLeftOutlined />
                </Button>
              </Tooltip>
              <Typography variant="h3" style={{ fontWeight: 700, }}>Add News</Typography>
            </Grid>
            <Grid item xs={12} md={12} >
              {/* <OrdersTable /> */}
              <Formik
                initialValues={{
                  name: '',
                  submit: null
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required('Name is required'),
                 


                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm, }) => {
                  try {
                    console.log(values)
                    console.log(ImageFile)
                    let ImageUrl;
                    if (ImageFile === null || ImageFile === "") {
                      toastAlert("error", "Image is required")

                    } else {
                      const postDataImage = {
                        image: ImageFile
                      };
                      ImageUrl = await postFormData(postDataImage); // Specify the endpoint you want to call
                      console.log(ImageUrl)
                    }
                    setSubmitting(true);
                    const postData = {
                      image: ImageUrl?.path,
                      title: values.name,
                      description: editorRef.current.getContent(),
                    };
                    try {
                      // craete product 
                      const apiData = await post('blogs/createBlog', postData); // Specify the endpoint you want to call
                      console.log("ADD Blog ")

                      console.log(apiData)
                      if (apiData.error) {
                        console.log("error true")
                        toastAlert("error", apiData.message)
                        setSubmitting(false);

                      } else {
                     
                        // resetForm();
                        setTimeout(() => {
                          // window.location.reload();
                          toastAlert("success", "Blog Added Successfully")
                          navigate('/latest_news')
                        }, 1000);

                        setStatus({ success: false });
                        setSubmitting(false);
                      }
                    } catch (error) {
                      toastAlert("error", "Something Went Wrong")
                      // setNextloader(false)
                      setStatus({ success: false });
                      setSubmitting(false);
                      // console.error('Error fetching data:', error);
                      // setNextloader(false)

                    }
                    setStatus({ success: false });
                    setSubmitting(false);
                  } catch (err) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,submitForm }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={3}>

                      <Grid item xs={12} md={12} >
                        <Grid container spacing={2} style={{ border: '1px solid lightGray', borderRadius: '10px', backgroundColor: 'white' }}>
                          <Grid item xs={12} md={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6} style={{ padding: '20px' }}>
                                <Stack spacing={1}>
                                  <Typography variant="h5">Title</Typography>
                                  <OutlinedInput
                                    id="email-login"
                                    type="text"
                                    value={values.name}
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    error={Boolean(touched.name && errors.name)}
                                  />
                                  {touched.name && errors.name && (
                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                      {errors.name}
                                    </FormHelperText>
                                  )}
                                </Stack>
                                <Stack spacing={1}>
                                  <Typography variant="h5">Description</Typography>
                                  <div >
                                  <Editor
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    initialValue={about_me}
                                    init={{
                                      height: 400,
                                      menubar: false,
                                      toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                        'alignleft aligncenter alignright alignjustify | ' +
                                        'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    // value={about_me}
                                    onChange={() => {
                                      if (editorRef.current) {
                                        setAbout_me(editorRef.current.getContent())

                                      }
                                    }
                                    }
                                    apiKey={apiKey} />
                                </div>
                                 
                                </Stack>
                               
                              
                              
                              </Grid>
                              <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Stack spacing={1}>
                                  {selectedImage === '' || selectedImage === null ? <>
                                    <Box onClick={handleButtonClick} style={{ width: '100%', marginInline: '2%', padding: '15px', backgroundColor: '#f2f0f0', border: '3px dotted #cecece', borderRadius: '10px' }}>
                                      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBlock: '10%', flexDirection: 'column' }}>

                                        <UploadOutlined style={{ fontSize: '50px', color: 'gray' }} />
                                        <Typography variant="h6">Choose an image</Typography>
                                  
                                      </Box>
                                    </Box>
                                  </> :
                                    <>
                                      <Box onClick={handleButtonClick} style={{ marginInline: '2%', backgroundColor: '#f2f0f0', border: '3px dotted #cecece', borderRadius: '10px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                      
                                        {selectedImage &&
                                          <Avatar src={selectedImage} alt="Selected" style={{ width: 'auto', height: '200px', border: '1px solid lightGray', borderRadius: '10px' }} variant="square" />
                                        }
                                        <AnimateButton>
                                          <Button onClick={() => setSelectedImage('')} disableElevation variant="outlined" size="medium" style={{ color: 'rgb(76 91 104)', fontWeight: 700, backgroundColor: 'white', border: '1px solid lightGray', boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)', marginBlock: '1%' }} color="secondary">
                                            Change Image
                                          </Button>
                                        </AnimateButton>
                                      </Box>

                                    </>}

                                  <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="fileInput"
                                    onChange={handleFileUpload}
                                    ref={fileInputRef}
                                  />


                                </Stack>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                     

                   
                      <Grid item xs={12} align="right">

                        <Button disabled={isSubmitting}
                          startIcon={
                            isSubmitting ?
                              <ClipLoader
                                color={"#fff"}
                                loading={isSubmitting}
                                size={20}
                              />
                              : null
                          }
                          type="submit" disableElevation variant="contained" size="medium" style={{ fontWeight: 700, color: 'white', boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)', marginInline: '2%' }} color="primary">
                          Save
                        </Button>
                       
                       
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>


      </Grid>

    </>
  )
};

export default NewsAdd;
