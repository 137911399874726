import { useEffect, useRef, useState } from "react";
// material-ui
import {
  Box,
  Menu,
  MenuItem,
  Button,
  Skeleton,
  TablePagination,
  Tooltip,
  Grid,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
// project import
import emptyImage from "../../assets/images/icons8-empty-64.png";
import { BASE_URL, get, post } from "Urls/api";
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { formatDate } from "utils/formatDate";
import AnimateButton from "components/@extended/AnimateButton";
import toastAlert from "components/ToastAlert/index";

export default function OrderTable() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  // get all products

  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [opendel, setOpendel] = useState(false);
  const handleClosedel = () => {
    setOpendel(false);
  };
  const [loading, setLoading] = useState(false);
  const DeleteProduct = async () => {
    try {
      setLoading(true);
      const postData = {
        faq_id: selectedIndex,
      };
      const apiData = await post(`review/deleteFaq`, postData); // Specify the endpoint you want to call
      console.log("Get all ");
      if (apiData.error) {
        setLoading(false);
        toastAlert("error", "Something went wrong");
      } else {
        setLoading(false);
        toastAlert("success", "FAQs Deleted Success");
        setOpendel(false);
        fetchAllproducts();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [loader, setLoader] = useState(true);

  // Use effect
  const fetchAllproducts = async () => {
    try {
      const apiData = await get("review/getAllFaqs"); // Specify the endpoint you want to call
      console.log("ALL Courses");
      console.log(apiData);

      if (apiData.error) {
        setLoader(false);
      } else {
        setProducts(apiData.result);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchAllproducts();
  }, [page]);
  return (
    <Box>
      {loader ? (
        <>
          <Grid container spacing={2} pt={10}>
            {/* For variant="text", adjust the height via font-size */}

            <Grid item xs={12} md={12}>
              <Skeleton variant="rectangular" height={100} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rectangular" height={100} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Skeleton variant="rectangular" height={100} />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} align="right">
              <TableContainer
                sx={{
                  width: "100%",
                  overflowX: "auto",
                  position: "relative",
                  display: "block",
                  maxWidth: "100%",
                  "& td, & th": { whiteSpace: "nowrap" },
                }}
              >
                <Table
                  aria-labelledby="tableTitle"
                  sx={{
                    "& .MuiTableCell-root:first-of-type": {
                      pl: 2,
                    },
                    "& .MuiTableCell-root:last-of-type": {
                      pr: 3,
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Question</TableCell>
                      <TableCell align="center">Answer</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.length === 0 ? (
                      <>
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Avatar
                                variant="square"
                                src={emptyImage}
                                alt="No Data"
                                style={{
                                  width: "64px",
                                  height: "auto",
                                  marginBlock: "2%",
                                }}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <>
                        {products
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <>
                              <TableRow
                                key={row?.product_id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                
                                <TableCell align="center">
                                  <Typography variant="h5">
                                    {row?.heading}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  {row?.name==="Number"||
                                  row?.name==="Email"||
                                  row?.name==="Location"?"-":

                                 <Typography
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "40ch",
                                    }}
                                    title={
                                      row?.description?.length > 40
                                        ? row?.description
                                        : null
                                    }
                                  >
                                    {row?.description?.length > 40
                                      ? `${row?.description?.slice(0, 40)}...`
                                      : row?.description}
                                  </Typography>}
                                 
                                </TableCell>

                             

                                <TableCell align="center">
                                  <Tooltip title="Action">
                                    <EllipsisOutlined
                                      ref={anchorRef}
                                      style={{
                                        fontSize: "30px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(event) => {
                                        setSelectedIndex(row.faq_id);
                                        setAnchorEl(event.currentTarget);
                                      }}
                                    />
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={12} align="right">
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={products.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                boxShadow: "0px 2px 30px -15px rgba(94,94,107,0.67)",
                mt: 1.5,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => {
              setOpendel(true);
              }}>
              Delete
            </MenuItem>
          </Menu>
          {/* delete Dialog  */}
          <Dialog
            open={opendel}
            onClose={handleClosedel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              sx={{ m: 0, p: 2, fontSize: "20px", fontWeight: 700 }}
              id="customized-dialog-title"
            >
              Confirmation Alert
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ fontSize: "15px", color: "black" }}
                  >
                    Are you sure you want to Delete?
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} align="right">
                  <Button
                    disableElevation
                    onClick={handleClosedel}
                    variant="outlined"
                    size="medium"
                    style={{
                      marginRight: "12px",
                      fontWeight: 700,
                      color: "gray",
                      boxShadow: " 0px 2px 30px -15px rgba(94,94,107,0.67)",
                    }}
                    color="secondary"
                  >
                    No
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={() => DeleteProduct()}
                    disableElevation
                    variant="contained"
                    size="medium"
                    style={{
                      fontWeight: 700,
                      color: "white",
                      boxShadow: " 0px 2px 30px -15px rgba(94,94,107,0.67)",
                    }}
                    color="error"
                  >
                    Yes
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
}
