import { lazy } from 'react';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import LatestNews from 'pages/latest_news/SamplePage';
import NewsAdd from 'pages/NewsAdd/SamplePage';
import Payment from 'pages/payment/SamplePage';
import FAQ from 'pages/faq/SamplePage';
import FaqAdd from 'pages/faqAdd/SamplePage';

// render - sample page
const Users=Loadable(lazy(() => import('pages/users/SamplePage')));
const UserDetail = Loadable(lazy(() => import('pages/userDetails/SamplePage')));
const ContactUs = Loadable(lazy(() => import('pages/contact_us/SamplePage')));
const UserAdd=Loadable(lazy(() => import('pages/userAdd/SamplePage')));
const Course=Loadable(lazy(() => import('pages/courses/SamplePage')));
const CourseDetail=Loadable(lazy(() => import('pages/courseDetails/SamplePage')));
const CourseAdd=Loadable(lazy(() => import('pages/courseAdd/SamplePage')));
const VideosData=Loadable(lazy(() => import('pages/videosData/SamplePage')));
const VideoDetail=Loadable(lazy(() => import('pages/videoDetail/SamplePage')));
const VideoAdd=Loadable(lazy(() => import('pages/videoAdd/SamplePage')));
const CourseEdit=Loadable(lazy(() => import('pages/courseEdit/SamplePage')));
const Reviews=Loadable(lazy(() => import('pages/reviews/SamplePage')));
const WebsiteContent=Loadable(lazy(() => import('pages/websiteContent/SamplePage')));
const WebsiteContentEdit=Loadable(lazy(() => import('pages/websiteContentEdit/SamplePage')));

const ReviewAdd=Loadable(lazy(() => import('pages/reviewAdd/SamplePage')));
const ReviewEdit=Loadable(lazy(() => import('pages/reviewEdit/SamplePage')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Course />
    },
    // {
    //   path: 'dashboard',
    //   children: [
    //     {
    //       path: 'default',
    //       element: <DashboardDefault />
    //     }
    //   ]
    // },
   
    {
      path: 'users',
      element: <Users />
    },
    {
      path: 'courses',
      element: <Course />
    },
    {
      path: 'payment',
      element: <Payment />
    },
    {
      path: 'reviews',
      element: <Reviews />
    },
    {
      path: 'websiteContent',
      element: <WebsiteContent />
    },
    
    {
      path: 'faq_add',
      element: <FaqAdd />
    },
    {
      path: 'videos',
      element: <VideosData />
    },
    {
      path: 'user_detail/:id',
      element: <UserDetail />
    },
    {
      path: 'course_detail/:id',
      element: <CourseDetail />
    },
    {
      path: 'video_detail/:id',
      element: <VideoDetail />
    },
   
    {
      path: 'userAdd',
      element: <UserAdd />
    },
    {
      path: 'newsAdd',
      element: <NewsAdd />
    },
    {
      path: 'courseAdd',
      element: <CourseAdd />
    },
    {
      path: 'reviewAdd',
      element: <ReviewAdd />
    },
    {
      path: 'reviewEdit/:id',
      element: <ReviewEdit />
    },
    {
      path: 'contentEdit/:id',
      element: <WebsiteContentEdit />
    },
    {
      path: 'faq',
      element: <FAQ />
    },
  

    {
      path: 'courseEdit/:id',
      element: <CourseEdit />
    },
    {
      path: 'videoAdd',
      element: <VideoAdd />
    },
    {
      path: 'videoAdd/:course_id',
      element: <VideoAdd />
    },
    {
      path: 'contact_us',
      element: <ContactUs />
    },
    {
      path: 'latest_news',
      element: <LatestNews />
    },
   
   
  
  ]
};

export default MainRoutes;
