// assets
import {BookOutlined,AlertOutlined , CommentOutlined,CaretRightOutlined,UserOutlined,DashboardOutlined,FileOutlined,MessageOutlined ,PhoneOutlined,InboxOutlined,AppstoreOutlined,AppstoreAddOutlined ,DollarOutlined} from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  InboxOutlined,
  AppstoreAddOutlined ,
  AppstoreOutlined ,
  MessageOutlined ,
  PhoneOutlined ,
  FileOutlined,
  UserOutlined ,
  CaretRightOutlined,
  BookOutlined ,
  CommentOutlined ,
  AlertOutlined ,
  DollarOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  // title: 'Navigation',
  type: 'group',
  children: [
   
    {
      id: 'courses',
      title: 'Course',
      type: 'item',
      url: '/courses',
      icon: icons.BookOutlined,
      breadcrumbs: false
    },
    {
      id: 'users',
      title: 'Users',
      type: 'item',
      url: '/users',
      icon: icons.UserOutlined,
      breadcrumbs: false
    },
    {
      id: 'videos',
      title: 'Videos',
      type: 'item',
      url: '/videos',
      icon: icons.CaretRightOutlined ,
      breadcrumbs: false
    },
    {
      id: 'payment',
      title: 'Payment Requests',
      type: 'item',
      url: '/payment',
      icon: icons.DollarOutlined,
      breadcrumbs: false
    },
    {
      id: 'contact_us',
      title: 'Contact us',
      type: 'item',
      url: '/contact_us',
      icon: icons.PhoneOutlined,
      breadcrumbs: false
    },
    {
      id: 'reviews',
      title: 'Reviews',
      type: 'item',
      url: '/reviews',
      icon: icons.CommentOutlined,
      breadcrumbs: false
    },
    {
      id: 'websitecontent',
      title: 'Website Content',
      type: 'item',
      url: '/websiteContent',
      icon: icons.FileOutlined,
      breadcrumbs: false
    },
    {
      id: 'faq',
      title: 'FAQS',
      type: 'item',
      url: '/faq',
      icon: icons.MessageOutlined,
      breadcrumbs: false
    },
    // {
    //   id: 'latest_news',
    //   title: 'Latest News',
    //   type: 'item',
    //   url: '/latest_news',
    //   icon: icons.AlertOutlined,
    //   breadcrumbs: false
    // },
  
   
  
  ]
};

export default dashboard;
