// material-ui
import {
  Typography, Grid, Button, Box,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Avatar,
  Stack,
  Tooltip,

} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import AnimateButton from 'components/@extended/AnimateButton';
import {
  PaperClipOutlined,
  UploadOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { ClipLoader } from "react-spinners";
import toastAlert from 'components/ToastAlert/index';
import { useNavigate } from 'react-router-dom';
import { get, post, postFormData } from 'Urls/api';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Rating from '@mui/material/Rating';

const FaqAdd = () => {
  //  Select Image 
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [ImageFile, setImageFile] = useState(null);


  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file)

      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [selectedProgram, setSelectedProgram] = useState('')



  return (
    <>
      <Grid container spacing={2}>


        <Grid item xs={12} md={12} style={{ position: 'relative' }}>

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              marginBottom: '2%'
            }}>
              <Tooltip title="Back">


                <Button
                  onClick={() => {
                    navigate(`/faq`)
                  }
                  }
                  className='poppinsRegularBold'
                  sx={{
                    // width: '100px',
                    paddingInline: '10px',
                    display: "block",
                    color: "#03356b",
                    backgroundColor: "none",
                    fontSize: '16px',
                    fontWeight: 600,
                    borderRadius: '50px',
                    textTransform: 'capitalize',
                    transition: 'background-color 0.3s, color 0.3s', // Add transition properties
                    transitionDelay: '0.3s', // Add transition delay for hover
                    marginRight: '10px',
                    // border: '1px solid #03356b',
                    '&:hover': {
                      backgroundColor: "#03356b",
                      color: 'white', // Change text color to blue on hover
                    },
                  }} >
                  <ArrowLeftOutlined />
                </Button>
              </Tooltip>
              <Typography variant="h3" style={{ fontWeight: 700, }}>Add FAQs</Typography>
            </Grid>
            <Grid item xs={12} md={12} >
              {/* <OrdersTable /> */}
              <Formik
                initialValues={{
                  name: '',
                  review: '',
                  submit: null
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required('Question is required'),
                  review: Yup.string().required('Answer is required'),


                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm, }) => {
                  try {
                    console.log(values)
                    // console.log(ImageFile)
                    // let ImageUrl;
                    // if (ImageFile === null || ImageFile === "") {
                    //   toastAlert("error", "User Image is required")

                    // } else {
                      // const img = new Image();
                      // img.src = URL.createObjectURL(ImageFile);
                      // img.onload = async () => {
                      //   if (img.width > 500 || img.height > 500) {
                      //     toastAlert("error", "Image dimensions should be 500x500px or less.");
                      //   } else {
                          // const postDataImage = {
                          //   image: ImageFile
                          // };
                          // ImageUrl = await postFormData(postDataImage); // Specify the endpoint you want to call
                          // console.log(ImageUrl)
                          setSubmitting(true);
                          const postData = {
                            // image: ImageUrl?.path,
                            heading: values.name,
                            description: values.review,
                          };
                          try {
                            // craete product 
                            const apiData = await post('review/createFAQ', postData); // Specify the endpoint you want to call
                            console.log("ADD Review ")
    
                            console.log(apiData)
                            if (apiData.error) {
                              console.log("error true")
                              toastAlert("error", apiData.message)
                              setSubmitting(false);
    
                            } else {
    
                              // resetForm();
                              setTimeout(() => {
                                // window.location.reload();
                                toastAlert("success", "FAQ Added Successfully")
                                navigate('/faq')
                              }, 1000);
    
                              setStatus({ success: false });
                              setSubmitting(false);
                            }
                          } catch (error) {
                            toastAlert("error", "Something Went Wrong")
                            // setNextloader(false)
                            setStatus({ success: false });
                            setSubmitting(false);
                            // console.error('Error fetching data:', error);
                            // setNextloader(false)
    
                          }
                        // }
                      // }

                    
                    // }

                    setStatus({ success: false });
                    setSubmitting(false);
                  } catch (err) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, submitForm }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={3}>

                      <Grid item xs={12} md={12} >
                        <Grid container spacing={2} style={{ border: '1px solid lightGray', borderRadius: '10px', backgroundColor: 'white' }}>
                          <Grid item xs={12} md={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6} style={{ padding: '20px' }}>
                                <Stack spacing={1}>
                                  <Typography variant="h5">Question</Typography>
                                  <OutlinedInput
                                    id="email-login"
                                    type="text"
                                    value={values.name}
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    error={Boolean(touched.name && errors.name)}
                                  />
                                  {touched.name && errors.name && (
                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                      {errors.name}
                                    </FormHelperText>
                                  )}
                                </Stack>
                                <Stack spacing={1}>
                                  <Typography variant="h5">Answer</Typography>

                                  <OutlinedInput
                                    id="review"
                                    type="text"
                                    value={values.review}
                                    name="review"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    error={Boolean(touched.review && errors.review)}
                                  />
                                  {touched.review && errors.review && (
                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                      {errors.review}
                                    </FormHelperText>
                                  )}
                                </Stack>

                       
                              </Grid>
                            
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>



                      <Grid item xs={12} align="right">

                        <Button disabled={isSubmitting}
                          startIcon={
                            isSubmitting ?
                              <ClipLoader
                                color={"#fff"}
                                loading={isSubmitting}
                                size={20}
                              />
                              : null
                          }
                          type="submit" disableElevation variant="contained" size="medium" style={{ fontWeight: 700, color: 'white', boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)', marginInline: '2%' }} color="primary">
                          Save
                        </Button>
                        {/* <Button
                          disabled={isSubmitting}
                          startIcon={
                            isSubmitting ?
                              <ClipLoader
                                color={"#fff"}
                                loading={isSubmitting}
                                size={20}
                              />
                              : null
                          }
                          onClick={() => {
                            submitForm().then(() => {
                              //  refresh page 
                              // window.location.reload();
                              // navigate('/new-route'); // replace '/new-route' with the path you want to navigate to
                            });
                          }} disableElevation variant="contained" size="medium" style={{ fontWeight: 700, color: 'white', boxShadow: ' 0px 2px 30px -15px rgba(94,94,107,0.67)' }} color="primary">
                          Save & Add More
                        </Button> */}

                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>


      </Grid>

    </>
  )
};

export default FaqAdd;
