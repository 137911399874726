// material-ui
import {
  Typography, Grid,Button
} from '@mui/material';
import OrdersTable from './OrdersTable';
import AnimateButton from 'components/@extended/AnimateButton';
import {
  PlusOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';


// ==============================|| SAMPLE PAGE ||============================== //
const LatestNews = () => {
  const navigate = useNavigate();


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h3" style={{ fontWeight: 700 }}>Latest News</Typography>
          <AnimateButton>
            <Button
              onClick={() => navigate('/newsAdd')}
              disableElevation startIcon={<PlusOutlined />} size="medium" color="primary" variant="contained">
              News
            </Button>
          </AnimateButton>
        </Grid>
   
        <Grid item xs={12} md={12}>
          <OrdersTable />
        </Grid>

      </Grid>

    </>
  )
};

export default LatestNews;
